
@keyframes breathing-glow {

    0%,
    100% {
        box-shadow: 0 0 40px rgba(181, 137, 4, 1);
    }

    50% {
        box-shadow: 0 0 15px rgba(181, 137, 4, 0.75);
    }
}


@keyframes breathing-glow-text {

    0%,
    100% {
        text-shadow: 0 0 30px rgba(181, 137, 4, 1);
    }

    50% {
        text-shadow: 0 0 15px rgba(181, 137, 4, 0.75);
    }
}

.card-modal-preview {
    
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    margin-top: 20px;
  
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; Ensure items are centered in both directions */
    
    height: 100%;
    min-height: 59%;
    
    /* background: rgba(0, 0, 0, 0); */
    background: none;
    max-height: 80vh;

    /* box-shadow: rgb(45, 50, 111) 0px 0px 10px; */
    /* border-color: rgb(45, 50, 111); */
    border:none;
    
  }
  
  #preview-img {
    max-height: 500px;
  }

  .video-preview {
    /*width: 100%; /* Let the video scale based on the modal's width */
    height: auto; /* Maintain aspect ratio */
    max-height: 90vh; /* Prevent the video from being too tall on larger screens */
    animation: breathing-glow 2s ease-in-out infinite;
  }
  
  .modal-title {
    font-size: 1.5em;
    color: rgb(239, 255, 255);
    text-align: center;
    margin-bottom: 0px;
    animation: breathing-glow-text 2s ease-in-out infinite;
  }
  
  .close-modal {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #f00;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .to-vault-button {
    padding: 10px 20px;
    color: white;
    cursor: pointer;
  }

  .trade-in-button {
    margin-top: 20px;
    padding: 10px 20px;
    color: white;
    cursor: pointer;
  }

  .content-container {
    /*
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    */
    max-width: 90vw;
  }