.viewport {

  /* Example: setting a max-width to ensure it doesn't get too wide on larger screens */
  height: 75vh;
  width: 60vw;
  /* Adjust height to maintain aspect ratio or set a fixed height */
  overflow: hidden;
  position: relative;
  border-bottom: 2px solid pink;
  border-top: 2px solid pink;
  /*
  margin: 20px auto;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-top: 5px;*/
  box-sizing: border-box;
}

.title-container {
  margin-top: 10px;
  margin-bottom: 10px
}
/* Ensure the cards container takes into account the padding of the viewport */
.cards-container {
  position: absolute;
  width: calc(100% - 40px);
  /* Subtract padding from both sides
  top: 100%;
  */
  
  left: 20px;
  /* Offset by the padding amount */
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 480px) {
  .viewport {
      min-height: 65vh;
  }
}

/* Adjust the card size for mobile screens */
@media (max-width: 768px) {
  .viewport {
      max-width: 95vw;
      /* Use a larger portion of the viewport width on smaller screens */
      height: auto;
      /* Adjust height based on content for mobile devices */
      /* Reduce padding on smaller screens */

      min-height: 75vh;
  }

  .cards-container {
      width: calc(100% - 20px);
      /* Adjust width calculation based on new padding */
      left: 10px;
      /* Adjust left offset based on new padding */
  }
}

/* New class for the animation */
.animate-scroll {
  animation: scrollCards 1s linear infinite;
}


@keyframes scrollCards {
  from {
      top: 100%;
  }

  to {
      top: -400%;
  }

  /* Adjust this based on the total height of your cards */
}

@keyframes breathing-glow {

  0%,
  100% {
      box-shadow: 0 0 40px rgba(181, 137, 4, 1);
  }

  50% {
      box-shadow: 0 0 15px rgba(181, 137, 4, 0.75);
  }
}


.target-card {
  /* Add a gold shadow border and slight expansion */
  border: 4px solid #FFD700;
  transform: scale(1.05);
  animation: breathing-glow 2s ease-in-out infinite;
}

.spin-button {
  transition: transform 0.2s ease-in-out;
  width: 90%;
  margin-top: 10px;
  margin-bottom: 20px;
  

}

.spin-button:enabled {
  animation: breathing-glow 2s ease-in-out infinite;
}

.spin-button:hover:enabled {
  transform: scale(1.05) translate(0, -2px);
  /* Addictive Features!
      1. Slight scale increase
      2. Glow breath effect
  */


}

.spin-button:active:enabled {
  transform: scale(.75);

}

.main-container {
  
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0%;
  padding: 0%;
  /* height: 100%; */
  overflow: clip;
  height: 100%;
  width:100%;
}

.other-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}