.contentDialog {
    border: none;
    padding: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9); /* Dark overlay */
  }
  
  .content {
    max-width: 90%;
    max-height: 80%;
  }
  
  .closeDialog {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 10px;
    font-size: 20px;
    background: transparent;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  /* Optional: Add a hover effect for better UX */
  .closeDialog:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  